(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular.module('wbOrder').config(config);

  function config($translateProvider) {
    $translateProvider.translations('hu', {
      'order.type.1': 'kártyaigénylés',
      'order.type.1.url': 'new-card',
      'order.type.2': 'kártyafeltöltés',
      'order.type.2.url': 'top-up',
      'order.type.3': 'biztonságos fájlküldés',
      'order.type.3.url': 'vip',
      'order.status.S': 'sikeres',
      'order.status.F': 'sikertelen',
      'order.legend': '{{type}} {{ status}}',
      'order.validation': 'Ellenőrzés',

      'order.company.name': 'Cég neve',
      'order.company.taxNum': 'Adóazonosító jel',

      'order.stuckWhileOrdering': 'Elakadt a rendelésben?',
      'order.steps.beforeYouBegin': 'Mielőtt elkezdené...',
      'order.steps.orderType': 'Rendelés típusa',
      'order.steps.cardData': 'Kártya adatok',
      'order.steps.billingData': 'Számlázási adatok',
      'order.steps.sendOrder': 'Megrendelés elküldése',

      'order.steps.analytics.registrationStep': 'Regisztrációs lépés',
      'order.steps.analytics.registration.base': 'Regisztráció',
      'order.steps.analytics.registration':
        'Új kártya rendelés regisztrációval',
      'order.steps.analytics.registration.1': 'Új kártya rendelés',
      'order.steps.analytics.registration.2': 'Kártya feltöltés',
      'order.steps.analytics.registration.3': 'Biztonságos fájlküldés',
      'order.steps.analytics.stepStarted': 'Elkezdve',
      'order.steps.analytics.stepComplete': 'Teljesítve',
      'order.steps.analytics.started': 'Indítás',
      'order.steps.analytics.start': 'Megrendelés elkezdve',
      'order.steps.analytics.duplicate': 'Megrendelés elkezdve másolással',
      'order.steps.analytics.ended': 'Megrendelés elküldve',
      'order.steps.analytics.stepNext': 'Tovább lépés',
      'order.steps.analytics.callback': 'Tovább lépés visszahívásra',
      'order.steps.analytics.callbackSent': 'Visszahívási igény beküldve',
      'order.steps.analytics.stepBack': 'Visszalépve erre',
      'order.steps.analytics.companySelected': 'Cég kijelölve',
      'order.steps.analytics.authenticated': '1. lépés',
      'order.steps.analytics.vipUpload.authenticated': '2. lépés',
      'order.steps.analytics.typeSelector.authenticated': '2. lépés',
      'order.steps.analytics.itemList.authenticated': '3. lépés',
      'order.steps.analytics.fileUpload.authenticated': '3. lépés',
      'order.steps.analytics.invoice.authenticated': '4. lépés',
      'order.steps.analytics.companyCallback.anonymous': 'Visszahívás kérés',
      'order.steps.analytics.orderStart.anonymous': '0. lépés',
      'order.steps.analytics.companyBasic.anonymous': '1. lépés',
      'order.steps.analytics.typeSelector.anonymous': '2. lépés',
      'order.steps.analytics.itemList.anonymous': '3. lépés',
      'order.steps.analytics.fileUpload.anonymous': '3. lépés',
      'order.steps.analytics.contract.anonymous': '4. lépés',
      'order.steps.analytics.invoice.anonymous': '5. lépés',
      'order.steps.analytics.success': 'Megrendelés befejezve',

      'order.steps.first.title': 'Az első lépés mielőtt elkezdené a rendelést',
      'order.steps.first.subtitle': 'Rövidítse le a rendelési folyamatot!',
      'order.steps.first.legend':
        'Kérjük adja meg, hogy mely cég/szervezet részére kíván rendelni! <strong>Ezen adatok segítségével lerövidíthető a rendelési folyamat</strong>, mert a rendelkezésünkre álló adatokat kitöltjük Ön helyett.',

      'order.soon': 'Hamarosan!',

      'order.newCardOrder': 'Kártyarendelés',
      'order.topUp': 'Kártyafeltöltés',

      'order.vip.title': 'Biztonságos fájlküldés',
      'order.vip.sampleFile.legend':
        'A következő felületen biztonságosan tud számunkra személyes adatot tartalmazó dokumentumot beküldeni. A kérésről Ügyfélszolgálatunk a feldolgozást követően tájékoztatja Önt. Felhívjuk figyelmét, hogy új kártya rendelését vagy kártyafeltöltést a bal oldalon található „Új kártya rendelés”, illetve a „Kártyafeltöltés” menüpontokban tud kezdeményezni.',
      'order.vip.note': 'Megjegyzés',

      'order.bulk.files.title': 'Kártyarendelés fájlfeltöltéssel',
      'order.bulk.files.info':
        'Egy fájlon belül csak azonos típusú kártyák szerepelhetnek.',
      'order.bulk.files.sampleFile': 'Kitöltendő Excel fájl',
      'order.bulk.files.sampleFile.legend':
        'Kérjük, töltse le a mintamegrendelőt, és töltsön ki minden mezőt. A letöltött táblázat nem formázható és nem bővíthető további oszlopokkal. Kitöltés után mentse el.',
      'order.bulk.files.uploadFile': 'Fájl feltöltése',
      'order.bulk.files.uploadFile.legend':
        'Kérjük, a kitöltött minta megrendelést ide töltse fel, majd kattintson a Tovább gombra.',
      'order.bulk.files.changeFile': 'Csere új fájlra',

      'order.bulk.files.error.fields.lastName': 'Családi név',
      'order.bulk.files.error.fields.firstName': 'Utónév',
      'order.bulk.files.error.fields.dateOfBirth': 'Születési idő',
      'order.bulk.files.error.fields.nameOnCard': 'Kártyán szereplő név',
      'order.bulk.files.error.fields.address': 'Cím',
      'order.bulk.files.error.fields.city': 'Város',
      'order.bulk.files.error.fields.zipCode': 'Irányítószám',
      'order.bulk.files.error.fields.phoneNumber': 'Telefon',
      'order.bulk.files.error.fields.email': 'Kártyabirtokos e-mail',
      'order.bulk.files.error.fields.cardData': 'Kártya adat',
      'order.bulk.files.error.fields.cardType': 'Kártyatípus',
      'order.bulk.files.error.fields.loadAmount': 'Feltöltési összeg',
      'order.bulk.files.error.fields.department': 'Részleg',
      'order.bulk.files.error.fields.deliveryAddress': 'Szállítási cím',
      'order.bulk.files.error.fields.recipient': 'Átvevő',
      'order.bulk.files.error.fields.csn': 'Kártya szériaszám',

      'order.bulk.files.error.field':
        '{{::field | translate}} oszlopban található hibák:',

      'order.companyData.details': 'Cégadatok',
      'order.companyData.hq': 'Székhely',

      'order.companyData.companyName': 'Cégnév',
      'order.companyData.companyNumber': 'Cégjegyzékszám',
      'order.companyData.vatNumber': 'Adóazonosító jel',
      'order.companyData.nameOnCard': 'Kártyán szereplő cégnév',
      'order.companyData.zipCode': 'Irányítószám',
      'order.companyData.city': 'Város',
      'order.companyData.address': 'Utca, házszám',
      'order.deliveredTo': 'Átvevő személy neve',
      'order.deliveredTo.lastName': 'Átvevő vezetékneve',
      'order.deliveredTo.firstName': 'Átvevő keresztneve',
      'order.companyData.billingDifferentFromHq':
        'Számlázási adatok különböznek a cég székhelyétől',
      'order.companyData.shippingDifferentFromHq':
        'Szállítási adatok különböznek a cég székhelyétől',
      'order.companyData.billingData': 'Számlázási adatok',
      'order.companyData.shippingData': 'Szállítási adatok',

      'order.ownerStatement.title': 'Tényleges Tulajdonosi Nyilatkozat (TTNY)',

      'order.ownerStatement.ownerDetails': 'Cégjegyzésre jogosult vezető',
      'order.ownerStatement.ownerDetails.title': 'Tulajdonos adatok',
      'order.ownerStatement.ownerDetails.titleId': 'Megszólítás',
      'order.ownerStatement.ownerDetails.name':
        'Cégjegyzésre jogosult vezető neve',
      'order.ownerStatement.ownerDetails.birthName':
        'Születéskori név (ha eltér)',
      'order.ownerStatement.ownerDetails.occupation': 'Beosztás',
      'order.ownerStatement.ownerDetails.citizenship': 'Állampolgárság',
      'order.ownerStatement.ownerDetails.identification.type':
        'Azonosító okmány típusa',
      'order.ownerStatement.ownerDetails.identification.number': 'Okmány száma',

      'order.ownerStatement.ownerAddress.title': 'Lakcím',
      'order.ownerStatement.ownerAddress.hungarianAddress.title':
        'Magyarországi cím',
      'order.ownerStatement.ownerAddress.hungarianAddress.subtitle':
        'Külföldi állampolgár esetén magyarországi tartózkodási hely megadása is szükséges',
      'order.ownerStatement.ownerAddress.foreigner': 'Külföldi állampolgár',
      'order.ownerStatement.ownerAddress.phoneNumber':
        'Telefonszám (opcionális)',
      'order.ownerStatement.ownerAddress.faxNumber': 'Fax (opcionális)',
      'order.ownerStatement.ownerAddress.email': 'E-mail-cím (opcionális)',

      'order.administration.title': 'Ügyintézési adatok',
      'order.administration.proforma.title': 'Díjbekérőhöz szükséges adatok',
      'order.administration.proforma.bankAccount': 'Bankszámlaszám',
      'order.administration.proforma.employeeNumber': 'Munkavállalók száma',
      'order.administration.proforma.phoneNumber': 'Telefonszám (opcionális)',
      'order.administration.proforma.faxNumber': 'Fax (opcionális)',
      'order.administration.proforma.email': 'Ügyintéző e-mail-címe',

      'order.administration.credentials.title': 'Belépéshez szükséges adatok',
      'order.administration.credentials.titleId': 'Megszólítás',
      'order.administration.credentials.name': 'Ügyintéző neve',
      'order.administration.credentials.lastName': 'Családi név',
      'order.administration.credentials.firstName': 'Utónév',
      'order.administration.credentials.password': 'Jelszó',
      'order.administration.credentials.password.legend':
        'Adja meg jelszavát, mellyel később egyszerűen 2 lépésben rendelhet! Amennyiben a megadott e-mail-címmel már regisztrált, kérjük, az ahhoz tartozó jelszavát adja meg.',
      'order.administration.credentials.password.tooltip':
        'A jelszónak legalább 8 karakter hosszúnak kell lennie <br />és tartalmaznia kell kis- és nagybetűt illetve számot is.',

      'order.invoice.title': 'Az Ön rendelése',
      'order.invoice.1.title': 'Új kártyák',
      'order.invoice.2.title': 'Kártyafeltöltések',
      'order.invoice.additionalFees': 'Egyéb költségek',
      'order.invoice.notes': 'Megjegyzés',

      'order.invoice.cardType.5.title': 'Ajándék és Bevásárló kártya',
      'order.invoice.cardType.6.title': 'Beiskolázási kártya',
      'order.invoice.cardType.7.title': 'Ajándék Fehér kártya',
      'order.invoice.cardType.8.title': 'Ajándék Arany kártya',
      'order.invoice.cardType.9.title': 'Kultúra és Sport kártya',
      'order.invoice.cardType.10.title': 'Ajándék Ezüst kártya',
      'order.invoice.cardType.11.title': 'Edenred Motivációs kártya',

      'order.invoice.cardType.15.title': 'Edenred Ezüst Kártya',
      'order.invoice.cardType.16.title': 'Edenred Arany Kártya',
      'order.invoice.cardType.17.title': 'Edenred Platina Kártya',

      'order.invoice.cardType.28.title': 'Edenred Egyedi kártya',
      'order.invoice.cardType.29.title': 'Edenred Komfort Kártya',
      'order.invoice.cardType.30.title': 'Edenred Ticket Restaurant SZÉP Kártya',

      'order.invoice.cardType.31.title': 'Edenred Gyerek Kártya',
      'order.invoice.cardType.32.title': 'Edenred Társ Kártya',
      'order.invoice.cardType.33.title': 'Edenred Szülő Kártya',
      'order.invoice.cardType.50.title': 'Rewin Standard Fizikai Kártya',
      'order.invoice.cardType.51.title': 'Rewin Standard Virtuális Kártya',

      'order.invoice.cardType.undefined.title': 'Ismeretlen típus',
      'order.invoice.cardType.0.title': 'Kártya feltöltés',
      'order.invoice.cardType.topup.title': 'Feltöltések',

      'order.invoice.item': 'Megnevezés',
      'order.invoice.cardType': 'Kártya típusa',
      'order.invoice.fees': 'Díjak',
      'order.invoice.qty': 'Darabszám',
      'order.invoice.net': 'Nettó',
      'order.invoice.vat': 'ÁFA',
      'order.invoice.gross': 'Bruttó',
      'order.invoice.total': 'Összesen',
      'order.invoice.wallets': 'Kártyazsebek',
      'order.invoice.sum': 'Összeg',
      'order.invoice.topUpDate': 'A kívánt szállítási dátum',
      'order.invoice.topUpDate.info': 'Kérjük válassza ki azt az időpontot, amikorra szeretné, hogy Edenred Ticket Restaurant SZÉP Kártyák a rátöltött juttatással együtt leszállításra kerüljenek a megadott szállítási címre. Ez az időpont az adategyeztetés leadása után követő 13. és 60. munkanap közé eshet. Ennél korábban nem tudjuk a kártyák gyártását és feltöltését garantálni; ez az idő szükséges ahhoz, hogy munkavállalói online szerződést kössenek velünk és a kártyák gyártása elkezdődjön. Felhívjuk figyelmét, hogy csak azon munkavállalóknak tudjuk a feltöltést elindítani, akik a feltöltési dátumig sikeresen online szerződést kötöttek.',
      'order.invoice.shippingAddress': 'A kártyák szállítási címe (fizikai kártyák esetén)',
      'order.invoice.estShipping': 'A szállítás várható időpontja (fizikai kártya esetén)',
      'order.invoice.estShippingLegend':
        'Az utalástól számított 6. munkanap 8-16 óra között.',
      'order.invoice.recipient': 'Átvevő személy neve (fizikai kártya esetén)',
      'order.invoice.topUpFee': 'Feltöltési díj ({{handlingFeePercent}}%)',
      'order.invoice.cardFee': 'Kártya díj',
      'order.invoice.shipping': 'Szállítási költség',
      'order.invoice.cardActivationNotice':
        '<h3>Tisztelt Ügyfelünk!</h3><p>Tájékoztatjuk, hogy a virtuális kártyák kártyaazonosítóit e-mailben küldjük el közvetlenül a Kártyabirtokosoknak a rendelési file-ban megadott e-mail címükre.</p><p>Új kártyákra vonatkozó megrendelés esetén a Kártyabirtokosok az aktiválási folyamatot online tudják elvégezni, a kártyához mellékelt (virtuális kártya esetében e-mailben küldött) kísérőlevél szerint.</p>',

      'order.sendOrder': 'Elküldöm a megrendelést',

      'order.thankYou': 'Megrendelését rögzítettük, köszönjük megtisztelő bizalmát!',
      'order.thankYou.title': 'Köszönjük megrendelését!',
      'order.thankYou.description': 'A rendelés feldolgozását megkezdtük, a rendelés visszaigazolás kiküldése akár 10 percet is igénybe vehet.',
      'order.backToHome': 'Vissza a főoldalra',
      'order.toDataReconciliationList': 'Korábbi Ticket Restaurant SZÉP Kártya adategyeztetések',

      'order.success.vipHeading':
        'Beküldött fájljait Ügyfélszolgálatunk megkapta.',
      'order.success.vipContent':
        'A feldolgozást követően tájékoztatást küldünk Önnek a következő e-mail-címre: <strong>{{email}}</strong>.',
      'order.success.furtherSteps': 'További lépések',
      'order.success.furtherStepsLegend':
        'Megrendelését rögzítettük, regisztráltuk rendszerünkben.',

      'order.success.registered.first.title': 'Díjbekérő kiküldése',
      'order.success.registered.first.legend':
        'Az Ön által korábban megadott e-mail-címre <strong>24 órán belül díjbekérőt kap.</strong>',
      'order.success.registered.second.title': 'Fizetés átutalással',
      'order.success.registered.second.legend':
        'A díjbekérőn feltüntetett összeget kérjük <strong>a megadott számlaszámra átutalni!</strong>',
      'order.success.registered.newCard.title': 'Kiszállítás',
      'order.success.registered.newCard.legend':
        'Megkezdjük a kártyák gyártását, és azokat futár fogja Önnek kiszállítani. <strong>Az e-számlát automatikusan emailben küldjük meg</strong>. Az e-számlák kézbesítéséhez Önnek lehetősége van további e-mail-címet is megadni, amit kérjük, ide küldjön el: info@rewin.hu',
      'order.success.registered.newCard.legend.2': 'Megkezdjük a kártyák gyártását, és azok elkészülte után <strong>futár fogja Önnek</strong> kiszállítani.',
      'order.success.sendVerificationEmail.title': 'Utalási Értesítő Kiküldése',
      'order.success.sendVerificationEmail.legend': 'Az Ön által megadott <strong>{{email}}</strong> e-mail-címre 1 munkanapon belül utalási értesítőt kap.',
      'order.success.paymentBankTransfer.legend': 'Az ott feltüntetett összeget kérjük a megadott számlaszámra 24 órán belül átutalni. Késedelmes utalás esetén nem tudjuk garantálni, hogy a kívánt szállítási dátumig a kártyákon megtörténjen a feltöltés.',
      'order.success.registered.topUp.title': 'Feltöltés',
      'order.success.topUp.legend': 'Megkezdjük a kártyák feltöltését.',
      'order.success.registered.topUp.legend':
        'Megkezdjük a kártyák feltöltését. <strong>Az e-számlát automatikusan emailben küldjük meg.</strong> Az e-számlák kézbesítéséhez Önnek lehetősége van  további e-mail-címet is megadni, amit kérjük, ide küldjön el: info@rewin.hu',
      'order.success.newUser.first.title': 'Fizetés átutalással',
      'order.success.newUser.first.legend':
        'Az Ön által megadott <strong>{{email}}</strong> e-mail-címre a szerződés aláírását és elektronikus visszaküldését követően 1 munkanapon belül <strong>díjbekérőt kap</strong>, amely előlegszámlaként funkcionál. Az ott feltüntetett összeget <strong>kérjük a megadott számlaszámra átutalni!</strong>',
      'order.success.newUser.second.title': 'Kiszállítás',
      'order.success.newUser.second.legend':
        'A díjbekérőn szereplő összeg megérkezése után megkezdjük a kártyák gyártását, és azok elkészülte után futár fogja Önnek kiszállítani azokat. <strong>Az eredeti számlát külön postázzuk.</strong>',
      'order.success.newUser.third.title': 'Elküldött rendelés',
      'order.success.newUser.third.legend':
        'Köszönjük szépen megkaptuk rendelését! A szerződéses kondíciókkal kollégánk hamarosan jelentkezik.',
      'order.success.sendVerificationEmail.2.title': ' Visszaigazoló kiküldése',
      'order.success.sendVerificationEmail.2.legend': 'Az Ön által megadott <strong>{{email}}</strong> e-mail-címre 1 órán belül visszaigazolást kap a leadott kártyaigényével kapcsolatban.',
      'order.success.contractStaff.title': 'Mukavállalók szerződtetése',
      'order.success.contractStaff.legend': 'Az adategyeztetés során megadott munkavállalói e-mail-címekre elküldjük az egyedi szerződéskötési linket. Munkavállalói a kapott e-mailben a linkre kattintva megkötik online szerződésüket.',
      'order.success.sendNotification.title': 'Emlékeztetők küldése',
      'order.success.sendNotification.legend': 'A még nem szerződött munkaválllalóknak e-mailben emlékeztetőket küldünk a folyamat során 3 alkalommal. A szerződtetés lezárása előtt Önnek is küldünk emlékeztetőt 2 alkalommal. Az emlékeztetőben megtalálja, hogyan tudja ellenőrizni még nem szerződött munkavállalói státuszát, és hogyan tud Ön is részükre emlékeztetőt küldeni.',
      'order.success.productionOfCards.title': 'Kártyák gyártása',
      'order.success.productionOfCards.legend': 'A kívánt szállítási dátumot megelőző 5. munkanapon lezárjuk a munkavállalói szerződtetést és megkezdődik a kártyák gyártása. Erről Önt e-mailben is értesítjük.',
      'order.success.topUpCards.title': 'Kártyák feltöltése',
      'order.success.topUpCards.multiwallet.legend': 'A kívánt szállítási dátumot megelőző 3. munkanapon feltöltjük Önnek a legyártott kártyák szériaszámos listáját, ennek megtörténtéről e-mailben értesítjük Önt. A szériaszámos lista segítségével tudja leadni feltöltésre vonatkozó megrendelését. A kívánt szállítási határidő tartásához kérjük, hogy az értesítést követő 24 órán belül adja le megrendelését! Amennyiben késve érkezik a kártyák feltöltésére vonatkozó megrendelés, a kártyákon a szállítás időpontjában nem lesz juttatás feltöltve, úgy a kártyák egyenleg feltöltése nélkül kerülnek kiszállításra.',
      'order.success.sendTransferNotification.title': 'Megrendelés összesítő megküldése/utalás',
      'order.success.sendTransferNotification.legend': 'Feltöltésre vonatkozó megrendelése után elküldjük Önnek e-mailben a megrendelés összesítőt. Kérjük, a megrendelés összesítő megérkezése után 24 órán belül utalja el a rajta szereplő számlaszámra a juttatási összeget. A feltöltések az utalást követően maximum 2 munkanapon belül elérhetőek a kártyákon.',
      'order.success.delivery.title': 'Kiszállítás',
      'order.success.delivery.legend': 'A kártyák gyártása és feltöltése után a kártyákat futárral szállítjuk ki az Ön által megadott címre.',

      'order.history': 'Korábbi rendelések',
      'order.created': 'Dátum',
      'order.acceptor': 'Megnevezés',
      'order.status': 'Típus',
      'order.product': 'Termék',
      'order.history.card.1.title': 'Cafeteria',
      'order.history.card.2.title': 'SZÉP',
      'order.history.card.3.title': 'Juttatási',
      'order.history.card.4.title': 'Komfort',
      'order.history.card.5.title': 'Család',
      'order.history.info.source': 'Automatikusan generált rendelés, forrás:',
      'order.history.info.source.nitro': 'Nitro kedvezmény platform',
      'order.history.info.source.ic': 'Instant Cafeteria',
      'order.amount': 'Összeg',
      'order.amount.header': 'Feltöltendő összeg',
      'order.more': 'Tovább',
      'order.filter': 'Szűrési feltételek',
      'order.filter.dateFrom': 'Kezdő dátum',
      'order.filter.dateTo': 'Végső dátum',
      'order.filter.submit': 'Frissítés',

      'order.filter.year': 'Év',
      'order.filter.month': 'Hónap',
      'order.filter.day': 'Nap',
      'order.filter.year.label': 'Születési év',
      'order.filter.month.label': 'Születési hónap',
      'order.filter.day.label': 'Születési nap',

      'order.cart': 'Kosár',

      'order.1.unique.title': 'Egyedi kártyarendelés',
      'order.benefit.1.unique.title': 'Egyedi kártyarendelés',
      'order.cafeteria.1.unique.title': 'Egyedi kártyarendelés',
      'order.standard.1.unique.title': 'Egyedi kártyarendelés',
      'order.multiWallet.1.unique.title': 'Edenred Ticket Restaurant SZÉP Kártyarendelés',
      'order.family.1.unique.title': 'Egyedi kártyarendelés',
      'order.1.unique.subtitle': '(Maximum 25 db kártya)',
      'order.1.unique.legend':
        'Maximum 25 db kártya megrendeléséhez ajánljuk, ahol Ön egyesével rögzítheti a megrendelni kívánt kártyákhoz szükséges adatokat.',

      'order.1.bulk.title': 'Tömeges kártyarendelés',
      'order.benefit.1.bulk.title': 'Tömeges kártyarendelés',
      'order.cafeteria.1.bulk.title': 'Tömeges kártyarendelés',
      'order.standard.1.bulk.title': 'Tömeges kártyarendelés',
      'order.multiWallet.1.bulk.title': 'Edenred Ticket Restaurant SZÉP Kártyarendelés fájlfeltöltéssel',
      'order.family.1.bulk.title': 'Tömeges kártyarendelés',
      'order.1.bulk.subtitle': '(1-9999 db kártya)',
      'order.1.bulk.legend':
        'A megrendelni kívánt kártyákat egy Excel fájl segítségével töltheti fel a rendszerünkbe. Elsősorban 25 feletti kártyadarabszám megrendeléséhez ajánljuk.',

      'order.2.unique.title': 'Egyedi kártyafeltöltés',
      'order.benefit.2.unique.title': 'Egyedi kártyafeltöltés',
      'order.cafeteria.2.unique.title': 'Egyedi kártyafeltöltés',
      'order.standard.2.unique.title': 'Egyedi kártyafeltöltés',
      'order.multiWallet.2.unique.title': 'Edenred Ticket Restaurant SZÉP  Kártyafeltöltés',
      'order.family.2.unique.title': 'Egyedi kártyafeltöltés',
      'order.2.unique.subtitle': '(Maximum 25 db kártya)',
      'order.2.unique.legend':
        'Maximum 25 db kártya feltöltéséhez ajánljuk, ahol Ön egyesével rögzítheti a feltölteni kívánt összegeket.',

      'order.2.bulk.title': 'Tömeges kártyafeltöltés',
      'order.benefit.2.bulk.title': 'Tömeges kártyafeltöltés',
      'order.cafeteria.2.bulk.title': 'Tömeges kártyafeltöltés',
      'order.standard.2.bulk.title': 'Tömeges kártyafeltöltés',
      'order.multiWallet.2.bulk.title': 'Edenred Ticket Restaurant SZÉP Kártya - tömeges kártyafeltöltés',
      'order.family.2.bulk.title': 'Tömeges kártyafeltöltés',
      'order.2.bulk.subtitle': '(1-9999 db kártya)',
      'order.2.bulk.legend':
        'A feltölteni kívánt kártyákat egy Excel fájl segítségével töltheti fel a rendszerünkbe. Elsősorban 25 feletti kártyadarabszám feltöltéséhez ajánljuk.',

      'order.unique.toast.save': 'Hozzáadva',
      'order.unique.toast.edit': 'Módosítva',
      'order.unique.toast.delete': 'Törölve',

      'order.back': 'Vissza',

      'order.itemList.info':
        'Egy fájlon belül csak azonos típusú kártyák szerepelhetnek.',
      'order.itemList.addNew': 'Új hozzáadása',
      'order.itemList.firstName': 'Utónév',
      'order.itemList.lastName': 'Családi név',
      'order.itemList.dob': 'Szül. dátum',
      'order.itemList.email': 'E-mail-cím',
      'order.itemList.csn': 'Kártyaszám',
      'order.itemList.multiWallet.csn': 'Kártya szériaszáma',
      'order.itemList.vatNumber': 'Adóazonosító Jel',
      'order.itemList.wallets': 'Zseb',
      'order.itemList.cardType.full': 'Kártya típusa',
      'order.itemList.1.emptyList.title':
        'Önnek még nincs rendelni kívánt kártyája!',
      'order.itemList.1.emptyList.info':
        'Az Új hozzáadása gombra kattintva adhat új kártyákat megrendeléséhez.',
      'order.itemList.2.emptyList.title':
        'Önnek még nincs feltölteni kívánt kártyája!',
      'order.itemList.2.emptyList.info':
        'Az Új hozzáadása gombra kattintva adhat új feltöltéseket megrendeléséhez.',
      'order.itemList.sum': 'Összeg',

      'order.itemDialog.benefit.1.create.title': 'Új kártya hozzáadása',
      'order.itemDialog.cafeteria.1.create.title': 'Új kártya hozzáadása',
      'order.itemDialog.standard.1.create.title': 'Új kártya hozzáadása',
      'order.itemDialog.multiWallet.1.create.title': 'Új kártya hozzáadása',
      'order.itemDialog.comfort.1.create.title': 'Új kártya hozzáadása',
      'order.itemDialog.family.1.create.title': 'Új kártya hozzáadása',

      'order.itemDialog.benefit.2.create.title': 'Új kártyafeltöltés',
      'order.itemDialog.cafeteria.2.create.title': 'Új kártyafeltöltés',
      'order.itemDialog.standard.2.create.title': 'Új kártyafeltöltés',
      'order.itemDialog.multiWallet.2.create.title': 'Új Edenred Ticket Restaurant SZÉP Kártyafeltöltés',

      'order.itemDialog.benefit.1.edit.title': 'Kártya szerkesztése',
      'order.itemDialog.cafeteria.1.edit.title': 'Kártya szerkesztése',
      'order.itemDialog.standard.1.edit.title': 'Kártya szerkesztése',
      'order.itemDialog.multiWallet.1.edit.title': 'Kártya szerkesztése',
      'order.itemDialog.comfort.1.edit.title': 'Kártya szerkesztése',
      'order.itemDialog.family.1.edit.title': 'Kártya szerkesztése',

      'order.itemDialog.benefit.2.edit.title': 'Kártya szerkesztése',
      'order.itemDialog.cafeteria.2.edit.title': 'Kártya szerkesztése',
      'order.itemDialog.standard.2.edit.title': 'Kártya szerkesztése',
      'order.itemDialog.multiWallet.2.edit.title': 'Kártya szerkesztése',
      'order.itemDialog.comfort.2.edit.title': 'Kártya szerkesztése',
      'order.itemDialog.family.2.edit.title': 'Kártya szerkesztése',

      'order.itemDialog.multiWallet.2.title': 'Új Edenred Ticket Restaurant SZÉP Kártyafeltöltés',
      'order.loader.inProgress':
        'A megrendelés feldolgozása folyamatban. Kérjük várjon!',

      'order.itemDialog.amountsByWallet': 'Zsebek szerinti feltöltési összeg',
      'order.itemDialog.amountToTopup': 'Feltöltendő összeg',

      'order.itemDialog.cardOwner.title': 'Kártyabirtokos adatai',
      'order.itemDialog.cardData.title': 'Kártya adatai',

      'order.itemDialog.close': 'Ablak bezárása',
      'order.itemDialog.save': 'Mentés',

      'order.itemList.name': 'Név',
      'order.itemList.address': 'Cím',
      'order.itemList.city': 'Település',
      'order.itemList.zip': 'Irsz.',
      'order.itemList.cardType': 'K.típus',
      'order.itemList.amount': 'Összeg',

      'order.itemDialog.lastName': 'Családi név',
      'order.itemDialog.firstName': 'Utónév',
      'order.itemDialog.csn': 'Szériaszám',
      'order.itemDialog.email': 'E-mail-cím',
      'order.itemDialog.nameOnCard': 'Kártyán szereplő név',
      'order.itemDialog.address': 'Utca, házszám',
      'order.itemDialog.note': 'Megjegyzés',

      'order.itemDialog.dob.full': 'Születési idő',
      'order.itemDialog.dob.full.label': 'Születési idő (pl. 1971.06.28)',
      'order.itemDialog.dob.hint': 'A helyes dátum formátum: 1980.01.01',
      'order.itemDialog.dob.year': 'Év',
      'order.itemDialog.dob.month': 'Hónap',
      'order.itemDialog.dob.day': 'Nap',
      'order.itemDialog.dob.year.label': 'Születési év',
      'order.itemDialog.dob.month.label': 'Születési hónap',
      'order.itemDialog.dob.day.label': 'Születési nap',

      'order.itemDialog.card.title': 'Megrendelni kívánt kártyák',
      'order.itemDialog.card.cardType': 'Kártya típusa',
      'order.itemDialog.card.amount': 'Feltöltendő összeg',
      'order.itemDialog.card.legend':
        'Az alábbi gombra kattintva mentheti el kártyarendelését és adhat új kártyát a rendeléséhez.',
      'order.itemDialog.card.benefit.saveAndContinue': 'Mentés és új kártyabirtokos hozzáadása',
      'order.itemDialog.card.family.saveAndContinue': 'Mentés és új személy hozzáadása',
      'order.itemDialog.card.standard.saveAndContinue': 'Mentés és új kártya hozzáadása',

      'order.itemDialog.cardTypes.title': 'Ismerje meg kártyáinkat',

      'order.itemDialog.delete': 'Törlés',
      'order.itemDialog.confirmDelete':
        'Biztosan törölni kívánja a kiválasztott elemet?',
      'order.itemDialog.cancel': 'Mégse',

      'order.generateFromPrevious': 'Újrarendelés',
      'order.bulk.files.download': 'Letöltés',
      'order.bulk.files.delete': 'Törlés',
      'order.bulk.files.delete.dialog':
        'Biztosan törli a feltöltött fájlt ({{fileName}})?',

      'order.errorDialog.title': 'Hiba',
      'order.errorDialog.legend':
        'A feltöltött Excel állomány hibákat tartalmaz. A gyorsabb javítás érdekében összeszedtük a talált problémákat. Kérjük, javítsa a jelzett cellák tartalmát, és töltse fel a javított fájlt!',

      'order.sampleFileDownloadAlert':
        'Kérjük, az Excel fájlt ne formázza, oszlopot ne töröljön ki vagy szúrjon be. A példaadatok a pontos kitöltést segítik, kérjük, ezeket ne törölje.',

      'order.cardInfo.open': 'Kattintson ide kártyáink összehasonlításához!',

      'order.cardComparison.title': 'Rewin kártyák összehasonlítása',
      'order.cardComparison.close': 'Ablak bezárása',
      'order.cardComparison.info': 'A részletes kártyainformációkért kattintson az oldal bal alsó menüjében az egyes kártyákra.',

      'order.comparisonTable.services': 'Mire használható?',
      'order.comparisonTable.affiliates': 'Hol használható?',
      'order.comparisonTable.taxRate': 'Adóvonzat',
      'order.comparisonTable.taxRate.text': 'Bérként adózik',

      'order.comparisonTable.features.1': 'Vásárlás POS terminálon',
      'order.comparisonTable.features.2': 'Apple Pay, Google wallet fizetésre alkalmas',
      'order.comparisonTable.features.3': 'E-commerce tranzakciók',
      'order.comparisonTable.features.4': '3 év érvényesség',
      'order.comparisonTable.features.5': 'Nincs kiszállítási díj',
      'order.comparisonTable.features.6': 'Nincs szükség kiosztásra',
      'order.comparisonTable.features.7': 'Családtagok támogatására használható kártyáink',
      'order.comparisonTable.features.8': 'Megszemélyesített kártyák (a kártyán szerepelnie kell a kártyabirtokos nevének)',
      'order.comparisonTable.features.9': 'Nem névre szóló kártyáink',
      'order.comparisonTable.features.10': 'Csekély értékű ajándék kiadására használható kártyáink',

      'order.comparisonTable.cards.benefit.1': 'Edenred Ezüst Kártya',
      'order.comparisonTable.cards.benefit.2': 'Edenred Arany Kártya',
      'order.comparisonTable.cards.benefit.3': 'Edenred Platina Kártya',
      'order.comparisonTable.cards.comfort': 'Edenred Komfort Kártya',
      'order.comparisonTable.cards.family.1': 'Edenred Gyerek Kártya',
      'order.comparisonTable.cards.family.2': 'Edenred Társ Kártya',
      'order.comparisonTable.cards.family.3': 'Edenred Szülő Kártya',
      'order.comparisonTable.cards.multiWallet': 'Edenred Ticket Restaurant SZÉP Kártya',

      'order.comparisonTable.cards.exclude': 'kivéve Platina Kártya',

      'order.comparisonTable.cardFee': 'Kártya díj',
      'order.comparisonTable.cardFee.text': '1 000 Ft',

      'order.comparisonTable.cardReplacementFee': 'Kártya csere',
      'order.comparisonTable.cardReplacementFee.text': '1 000 Ft',

      'order.comparisonTable.deliveryFee': 'Szállítási költség',
      'order.comparisonTable.deliveryFee.text': '999 Ft',

      'order.comparisonTable.handlingFee': 'Kezelési költség',
      'order.comparisonTable.cards.1.handlingFee': '1.8%',
      'order.comparisonTable.cards.2.handlingFee': '2.6%',
      'order.comparisonTable.cards.3.handlingFee': '3.7%',
      'order.comparisonTable.cards.4.handlingFee': '1.8%',
      'order.comparisonTable.cards.5.handlingFee': '1.8%',
      'order.comparisonTable.cards.6.handlingFee': 'Egyedi ajánlat alapján',

      'order.comparisonTable.cards.1.name': 'Rewin Standard Virtuális Kártya',
      'order.comparisonTable.cards.1.services':
        'A hagyományos cafeteria kártyákhoz hasonlóan korlátozottabb, de még mindig rendkívül széles körben.',
      'order.comparisonTable.cards.1.affiliates':
        'Több mint 70.000 szerződőtt partnerünknél. ',
      'order.comparisonTable.cards.1.taxRate': '40,71%',

      'order.comparisonTable.cards.2.name': 'Rewin Standard Fizikai Kártya',
      'order.comparisonTable.cards.2.services':
        'Termék- és szolgáltatások köre korlátozás nélkül.',
      'order.comparisonTable.cards.2.affiliates':
        'Minden magyarországi POS terminállal rendelkező üzletben és online vásárlásnál. ',
      'order.comparisonTable.cards.2.taxRate': '40,71%',

      'order.comparisonTable.cards.3.name': 'Edenred Családi Kártyák',
      'order.comparisonTable.cards.3.services':
        'Termék- és szolgáltatások köre korlátozás nélkül.',
      'order.comparisonTable.cards.3.affiliates':
        'Az Aranykártyával megegyezően használható vásárlásra. Készpénzfelvételi lehetőség minden magyarországi ATM-ből.',
      'order.comparisonTable.cards.3.taxRate': '40,71%',

      'order.comparisonTable.cards.4.name': 'Edenred Ticket Restaurant SZÉP Kártya',
      'order.comparisonTable.cards.4.services':
        'tankönyv, taneszköz, íróasztal, bútor, lakberendezés, ruházat, sportruházat, cipő, táska, számítástechnikai-, barkács- és optikai termékek',
      'order.comparisonTable.cards.4.affiliates': '30 000',
      'order.comparisonTable.cards.4.taxRate': '40,71%',

      'order.comparisonTable.cards.5.name': 'Edenred Kultúra és Sport Kártya',
      'order.comparisonTable.cards.5.services':
        'kultúra belépőjegyek (múzeum,  fesztiválok, táncelőadás, színház, koncert, cirkusz) és sport belépőjegyek',
      'order.comparisonTable.cards.5.affiliates': '1 000',
      'order.comparisonTable.cards.5.taxRate': '0% és korlátlan',

      'order.comparisonTable.cards.6.name': 'Edenred Motivációs Kártya',
      'order.comparisonTable.cards.6.services':
        'Alkalmazottak és üzleti partnerek motiválása, kulcsemberek megtartása:  ajándék, törzsgárda jutalom, év végi vagy év közi jutalom, 13. havi juttatás, nyereményjáték stb.',
      'order.comparisonTable.cards.6.affiliates': '70 000',
      'order.comparisonTable.cards.6.taxRate': '40,71%',

      'order.exitIntentDialog.title': 'Elakadt a rendelésben? Segíthetünk!',

      'order.exitIntentDialog.form.name': 'Név',
      'order.exitIntentDialog.form.companyName': 'Cégnév',
      'order.exitIntentDialog.form.phone': 'Telefonszám',
      'order.exitIntentDialog.form.email': 'E-mail-cím',

      'order.exitIntentDialog.legend':
        'Munkatársaink egy munkanapon belül visszahívják. Kérjük, visszahívás kéréséhez adja meg adatait, vagy keressen minket alábbi elérhetőségeinken:',
      'order.exitIntentDialog.contacts.phone': '+36 1 413 3333',
      'order.exitIntentDialog.contacts.email': 'info@rewin.hu',

      'order.exitIntentDialog.actions.close': 'Nem, köszönöm',
      'order.exitIntentDialog.actions.submit': 'Segítséget kérek!',
      'order.exitIntentDialog.sent': 'Visszahívási kérelmét rögzítettük.',

      'order.callback.title': 'Visszahívást kérek',
      'order.callback.standalone.heading':
        'Szeretnénk Önt is ügyfeleink között tudni!',
      'order.callback.merchant.heading': 'Szeretne Edenred elfogadóhely lenni?',
      'order.callback.legend':
        'Amennyiben nem tudta leadni rendelését, mert nem állt rendelkezésére az összes szükséges adat, kérem töltse ki az alábbi űrlapot!<br/>Ezt követően szakértő kollégánk rövid időn belül visszahívja és végigvezeti Önt az online szerződéskötés és ezzel együtt az első rendelés leadásának folyamatán.',
      'order.callback.standalone.legend':
        'Amennyiben Ön munkáltató, és termékünk felkeltette érdeklődését, kérjük adja meg elérhetőségét, hogy bővebb tájékoztatást adhassunk.',
      'order.callback.merchant.legend':
        'Adja meg adatait és rövid időn belül visszahívjuk Önt!',
      'order.callback.name': 'Név',
      'order.callback.companyName': 'Cégnév',
      'order.callback.phone': 'Telefonszám (közvetlen elérhetőség)',
      'order.callback.customerType': 'Ügyfél kategória',
      'order.callback.customerType.1': 'Munkáltató',
      'order.callback.customerType.2': 'Munkavállaló',
      'order.callback.customerType.3': 'Elfogadóhely',
      'order.callback.customerType.1.analytics': 'munkáltató',
      'order.callback.customerType.2.analytics': 'munkavállaló',
      'order.callback.customerType.3.analytics': 'elfogadóhely',
      'order.callback.customerType.1.analytics.full':
        'Munkáltatói igény beküldve',
      'order.callback.customerType.2.analytics.full':
        'Munkavállalói igény beküldve',
      'order.callback.customerType.3.analytics.full':
        'Elfogadóhelyi igény beküldve',
      'order.callback.submit': 'Küldés',
      'order.callback.cancel': 'Mégsem',
      'order.callback.success': 'Kérelmét továbbítottuk!',

      'order.orderStart.title.1': 'Kártya rendelés és online szerződéskötés',
      'order.orderStart.title.2': 'Visszahívás kérés',
      'order.orderStart.aboutCards': 'Megnézem',
      'order.orderStart.order': 'Rendelés, szerződéskötés',
      'order.orderStart.text':
        'Cége online szerződése az első megrendelés leadásakor automatikusan létrejön.<br/>A rendelés leadása <strong>5 egyszerű lépésből</strong> áll és kb. <strong>10 perc</strong>et vesz igénybe.<br/>A folyamat megkezdése előtt kérjük, végezze el a következőket:',
      'order.orderStart.steps.1.title': 'Cégadatok előkészítése',
      'order.orderStart.steps.1.description':
        '(cégnév, adószám, tulajdonosi és kapcsolattartói adatok)',
      'order.orderStart.steps.2.title': 'Rendelendő kártyák kiválasztása',
      'order.orderStart.steps.3.title':
        'Leendő kártyatulajdonosok, munkavállalók adatainak összegyűjtése',
      'order.orderStart.steps.3.description':
        '(név, születési idő, lakcím, e-mail-cím)',
      'order.orderStart.steps.4.title': 'Feltöltendő összeg meghatározása',
      'order.orderStart.callback.title': 'Írjon nekünk',
      'order.orderStart.callback.text':
        'Amennyiben nem rendelkezik a fenti adatokkal vagy még nincs pontos listája a rendelendő kártyákról, töltse ki az alábbi űrlapot és szakértő kollégánk rövid időn belül felkeresi Önt!',
      'order.steps.analytics.exitIntent.category': 'Kilépő pop-up',
      'order.steps.analytics.exitIntent.action': 'Küldés',

      'order.productTypeSelector.title': 'Rewin kártya választó',
      'order.productTypes.3.title': 'Rewin Standard kártyák',
      'order.productTypes.3.content': '<strong>Fizikai kártya</strong>: Letisztult, modern Mastercard kártya, mely kiváló juttatási és ösztönző megoldás minden korosztály számára.<br /> <strong>Virtuális kártya</strong>: Gyorsan és költséghatékonyan kiosztható, modern fizetőeszköz az innovatív megoldások kedvelőinek.',
      'order.productTypes.1.title': 'Hagyományos cafeteria kártyák',
      'order.productTypes.1.content': '2020. február 1-jétől <strong>a mágnescsíkos Edenred Cafeteria Kártyák helyett Edenred Juttatási Kártya szerződés keretében kizárólag jóval modernebb, chipes, érintéses fizetésre alkalmas Juttatási Kártyák rendelhetőek</strong>. Kártyacsere és kártya megújítás helyett is kizárólag új Juttatási kártyáinkat tudjuk biztosítani. Önök <strong>még nem rendelkeznek Edenred Juttatási Kártya szerződéssel</strong>, ezért <strong>kérjük, keressék kapcsolattartójukat a megszokott telefonszámon</strong>. A <strong>2020. február 1. előtt kibocsátott mágnescsíkos kártyák</strong> a rajtuk található <strong>lejárati ideig a vonatkozó szerződési feltételek szerint használhatók.</strong>',
      'order.productTypes.1.dialog.title': 'Kedves Partnerünk!',
      'order.productTypes.1.dialog.close': 'Rendben',
      'order.productTypes.2.title': 'Edenred Ticket Restaurant SZÉP Kártya',
      'order.productTypes.2.content': 'Digitális megoldás a munkavállalók minőségi étkezéséért. Kedvező adózással, a jól ismert SZÉP kártya konstrukcióval, fókuszban az étkezéssel. Felhasználók számára kényelmes, gyors online szerződéskötéssel, Mastercard technológia által biztosított érintéses fizetéssel.',
      'order.productTypes.2.wallets.0': 'Szálláshely zseb',
      'order.productTypes.2.wallets.1': 'Vendéglátás zseb',
      'order.productTypes.2.wallets.2': 'Szabadidő zseb',
      'order.productTypes.4.title': 'Edenred Komfort kártya',
      'order.productTypes.4.content': 'Modern eldobható kártya eseti juttatásokhoz, amiben a papír utalványok egyszerűségét ötvöztük a kártyák rugalmasságával. Alkalmi juttatásokhoz ajánljuk, minimum 500 Ft -tal maximum 45.000 Ft-tal tölthető fel.',
      'order.productTypes.5.title': 'Edenred családi kártyák',
      'order.productTypes.5.content': 'Munkavállalók családjának közvetlen támogatására.',
      'order.productTypeSelector.close': 'Ablak bezárása',
      'order.productTypeSelector.submit': 'Tovább',

      'order.replacementCard.title': 'Cserekártya rendelés',
      'order.replacementCard.description':
        'Itt van lehetősége megrendelni a kártyabirtokosok elveszett/ellopott/sérült kártyáinak cserekártyáját. Kérjük, adja meg az érintett Kártyabirtokos nevét és a pótolandó kártya 8 számjegyű kártyaazonosítóját!',
      'order.replacementCard.successText': 'Köszönjük, rendelését rögzítettük.',

      'order.comfortCard.title': 'Edenred Komfort kártya rendelés',
      'order.comfortCard.info': 'A kártya minimum 500 Ft-tal maximum 45.000 Ft-tal tölthető fel. A kártya utólagosan nem tölthető fel.',
      'order.comfortCard.submit': 'Megrendelés',
      'order.comfortCard.itemList.loadAmountPerCard':
        'Feltöltendő összeg kártyánként',
      'order.comfortCard.itemList.quantity': 'Mennyiség',
      'order.comfortCard.itemList.loadAmount': 'Feltöltendő összeg',
      'order.comfortCard.itemList.sum': 'Összesen',

      'order.multiWallet.recreation.title': 'Szabadidő',
      'order.multiWallet.accommodation.title': 'Szálláshely',
      'order.multiWallet.meal.title': 'Vendéglátás',

      'order.multiWallet.title': 'Edenred Ticket Restaurant SZÉP Kártya adategyeztetés',
      'order.multiWallet.info':
        '<p>Edenred Ticket Restaurant SZÉP Kártya szolgáltatás igénybevételéhez a kártyabirtokosnak szerződést kell kötnie a szolgáltatóval. A munkáltató által leadott kártyarendelési igény alapján a leendő kártyabirtokosnak e-mail üzenetben küldjük meg az egyedi szerződés megkötéséhez szükséges linket. A kártyák gyártása a kártyabirtokosok online történő szerződéskötése után kezdődik meg.</p>Fontos:<ul><li>info@-cal nem kezdődhet e-mail-cím</li><li>egy e-mail-címre egy Edenred Ticket Restaurant SZÉP Kártya igényelhető csak</li></ul>',
      'order.multiWallet.submit': 'Tovább',

      'order.multiWallet.bulk.files.title': 'Tömeges Edenred Ticket Restaurant SZÉP Kártya rendelési igény leadás',
      'order.multiWallet.bulk.files.info': 'Egy fájlon belül csak azonos típusú kártyák szerepelhetnek.',
      'order.dataReconciliation.process.title': 'Edenred Ticket Restaurant SZÉP Kártya - kártyarendelés és kártyafeltöltés folyamata',
      'order.dataReconciliation.close': 'Bezárás',
      'order.dataReconciliation.process.1.title': 'Adategyeztető lap feltöltése',
      'order.dataReconciliation.process.2.title': 'Kártyabirtokosok szerződéskötése',
      'order.dataReconciliation.process.3.title': 'Szériaszámok megérkeznek',
      'order.dataReconciliation.process.4.title': 'Feltöltés',

      'order.dataReconciliation.process.1.info': 'Kérjük, a mintaadatbázisnak megfelelően készítse el azon munkavállalóinak adatbázisát, akiknek Edenred Ticket Restaurant SZÉP Kártyát szeretne igényelni, majd töltse fel a felületre.',
      'order.dataReconciliation.process.2.info': 'Munkavállalói az adategyeztető lapon szereplő e-mail-címükre automatikus értesítést kapnak. Az abban lévő linkre kattintva rövid adategyeztetés után a munkavállalók és a pénzforgalmi szolgáltató között a szerződés elektronikus úton létrejön.',
      'order.dataReconciliation.process.3.info': 'Miután munkavállalóival létrejött a szerződés, a munkavállalók Edenred Ticket Restaurant SZÉP Kártyáihoz tartozó szériaszámokat felhasználói fiókjában megtekintheti és letöltheti.',
      'order.dataReconciliation.process.4.info': 'A szériaszámok alapján kezdeményezheti a Edenred Ticket Restaurant SZÉP Kártyák feltöltését. A rendelési mintafájl alapján állítsa össze a feltöltendő kártyák listáját, adja meg a feltöltendő összegeket és töltse fel a fájlt.',

      'order.multiWalletDataReconciliationList.title': 'Korábbi Ticket Restaurant SZÉP Kártya adategyeztetések',
      'order.multiWalletDataReconciliationList.info': 'Ezen a felületen különböző szűrési paraméterek alapján ellenőrizheti leadott Edenred Ticket Restaurant SZÉP Kártyarendelései állapotát. Itt kezdeményezheti még nem szerződőtt dolgozói újraértesítését, vagy pl. munkaviszony megszűnése esetén a szerződéskötési folyamat megszakítását/kártyarendelés törlését.',

      'order.multiWalletDataReconciliationList.new': 'Új Adategyeztetés',
      'order.multiWalletDataReconciliationList.topup': 'Kártyafeltöltés',
      'order.multiWalletDataReconciliationList.filter': 'Szűrés',

      'order.multiWalletDataReconciliationList.form.name': 'Munkavállaló neve',
      'order.multiWalletDataReconciliationList.form.status': 'Státusz',
      'order.multiWalletDataReconciliationList.form.date': 'Beküldés dátuma',
      'order.multiWalletDataReconciliationList.form.fromDate': '-tól',
      'order.multiWalletDataReconciliationList.form.toDate': '-ig',

      'order.multiWalletDataReconciliationList.process.0.title': 'Minden státusz',
      'order.multiWalletDataReconciliationList.process.1.title': 'Rögzítve',
      'order.multiWalletDataReconciliationList.process.2.title': 'Munkavállaló értesítve',
      'order.multiWalletDataReconciliationList.process.3.title': 'Munkavállaló szerződtetve',
      'order.multiWalletDataReconciliationList.process.4.title': 'Kártya rendelés leadva',
      'order.multiWalletDataReconciliationList.process.5.title': 'Törölt',
      'order.multiWalletDataReconciliationList.process.6.title': 'Lejárt',

      'order.multiWalletDataReconciliationList.table.header.orderSent': 'Beküldés dátuma',
      'order.multiWalletDataReconciliationList.table.header.notificationSent': 'Értesítés dátuma',
      'order.multiWalletDataReconciliationList.table.header.lastName': 'Családi név',
      'order.multiWalletDataReconciliationList.table.header.firstName': 'Utónév',
      'order.multiWalletDataReconciliationList.table.header.contract': 'Szerződéskötés',
      'order.multiWalletDataReconciliationList.table.header.cardOrder': 'Kártyarendelés',
      'order.multiWalletDataReconciliationList.table.header.email': 'E-mail-cím',
      'order.multiWalletDataReconciliationList.table.header.status': 'Státusz',

      'order.multiWalletDataReconciliationList.action.delete': 'Törlés',
      'order.multiWalletDataReconciliationList.action.notify': 'Újraértesítés',

      'order.multiWalletDataReconciliationList.selection.selectAll': 'Mindet kijelöl / kijelölés megszüntetése',

      'order.multiWalletDataReconciliationList.toast.delete': 'Sikeres törlés',
      'order.multiWalletDataReconciliationList.toast.email': 'Az értesítéseket kiküldtük'
    });
  }
})();
